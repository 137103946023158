<template>
	<v-card flat>
		<v-card-text v-if="toc.length">
			<v-treeview
				:items="toc"
				open-on-click
				return-object
				activatable
				:active.sync="active"
			>
				<!-- Prepend slot -->
				<template v-slot:prepend="{ item }">
					<v-icon
						v-if="!isWorkRoute && item.origin == 'private' && !item.sub_level"
						small
						color="wr_blue_1"
						@click.stop="showChapterDialog(item)"
					>
						$vuetify.icons.values.edit
					</v-icon>
					<v-chip
						v-if="!isWorkRoute && item.id == -1"
						small
						color="success"
						dark
						outlined
						depressed
						@click="showChapterDialog(item)"
					>
						<v-icon left small>$vuetify.icons.values.plus</v-icon>
						{{ $t("app.add_subchapters") }}
					</v-chip>

					<!-- This will be for last item -->
					<v-icon v-if="item.icon" color="pink darken-1">
						{{ item.icon }}
					</v-icon>

					<!-- This is to edit the subchapters -->
					<v-icon
						v-if="!isWorkRoute && item.sub_level"
						small
						color="pink darken-1"
						@click.native="confirmDel(item)"
						>$vuetify.icons.values.delete</v-icon
					>
					<v-icon
						v-if="!isWorkRoute && item.sub_level"
						small
						color="wr_blue_1"
						class="mx-4"
						@click.native="showChapterDialog(item)"
						>$vuetify.icons.values.edit</v-icon
					>
				</template>

				<!-- Middle slot -->
				<template v-slot:label="{ item }">
					<div v-if="item.id !== -1">
						<span :class="item.sub_level ? `text-decoration-underline` : ``">{{
							$te(item.title) ? $t(item.title) : item.title
						}}</span>
					</div>
				</template>

				<!-- Append slot -->
				<template v-slot:append="{ item }">
					<v-icon
						v-if="!isWorkRoute && item.origin == 'private' && !item.sub_level"
						small
						color="pink darken-1"
						@click.native="confirmDel(item)"
						>$vuetify.icons.values.delete</v-icon
					>
				</template>
			</v-treeview>

			<!-- FIXME:: uncomment after Ofek's event -->

			<!-- <Checklist /> -->

			<!-- <MentorSuggestion /> -->

			<PopulateChapterDialog
				v-if="dialog"
				v-model="dialog"
				:dialog="dialog"
				:prop_chapter="edit_chapter"
				@close="dialog = false"
			/>

			<!-- Confirmation box for delete operation -->
			<TheConfirmation
				v-model="del_dialog"
				:dialog="del_dialog"
				@cancel="del_dialog = false"
				@confirm="deleteChapter()"
			></TheConfirmation>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PopulateChapterDialog from "@/components/research/modules/write/stages/researchPaper/PopulateChapterDialog.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";
import * as featureComponents from "@/components/research/modules/write/stages/researchPaper/features/index.js";
import { researchPaperTOCMixin } from "@/mixins/researchPaper/tocMixin.js";

export default {
	name: "ResearchPaperTableOfContent",

	mixins: [researchPaperTOCMixin],

	data() {
		return {
			active: [],
			edit_chapter: {},
			chapter_to_delete: null,
			del_dialog: false,
			dialog: false,
		};
	},

	watch: {
		active(newVal, oldVal) {
			if (this.dialog || this.del_dialog) {
				return;
			}
			if (newVal[0]) {
				if (newVal[0].link) {
					this.$eventBus.$emit("update-tab", newVal[0].link);
				}

				if (newVal[0].action) {
					this[newVal[0].action]();
				}
			}
		},
	},

	computed: {
		...mapState({
			template: (state) => state.researchPaper.template,
		}),

		isWorkRoute() {
			return this.$route.name == "ResearchPaperWork";
		},
	},

	components: {
		PopulateChapterDialog,
		TheConfirmation,
		...featureComponents,
	},

	methods: {
		showChapterDialog(edit_chapter = {}) {
			try {
				this.edit_chapter = edit_chapter;
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		confirmDel(item) {
			try {
				this.chapter_to_delete = item;
				this.del_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteChapter() {
			try {
				this.$loader.start();

				// First delete the respected editor
				let res = await this.$store.dispatch("etherpad/deletePad", {
					pad_id: this.chapter_to_delete.uuid,
				});

				// Now, delete the chapter
				await this.$store.dispatch("researchPaper/delChapter", {
					id: this.chapter_to_delete.id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
				this.del_dialog = false;
			}
		},
	},
};
</script>
