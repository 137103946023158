<template>
	<v-dialog :value="dialog" scrollable max-width="550px" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label: toEditChapter
					? $t('app.edit_chapter')
					: toAddChapter
					? $t('app.add_chapter')
					: $t('app.add_subchapter'),
			}"
			:action="{
				label: 'app.save',
				event: 'save',
			}"
			@close="$emit('close')"
			@save="submit()"
		>
			<template v-slot:dialog-content>
				<div v-if="toAddSubChapter">
					<b>{{ prop_chapter.parent.title }}</b>
					> {{ $t("app.new_subchapter") }}
				</div>

				<v-form ref="form" class="mt-9">
					<v-text-field
						v-model="form.title"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.title')"
					></v-text-field>

					<v-textarea
						v-model="form.content"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.write_sentences_about_content')"
					></v-textarea>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import { mapGetters, mapState } from "vuex";
import AppDialog from "@/components/ui/AppDialog.vue";
import { generateRandomString } from "@/utils/helpers";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

export default {
	name: "PopulateChapterDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		prop_chapter: {
			required: true,
			type: Object,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			form: {
				title: "",
				content: "",
				level: null,
				sub_level: 0,
			},
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapState({
			researchTemplate: (state) => state.researchPaper.template,
		}),
		...mapGetters({
			privateChapterCount: "researchPaper/getPrivateChapterCount",
			userProfile: "user/getProfile",
		}),

		isPropChapEmpty() {
			return !Object.keys(this.prop_chapter).length;
		},

		toAddChapter() {
			return this.isPropChapEmpty;
		},

		toAddSubChapter() {
			return !this.isPropChapEmpty && this.prop_chapter.parent;
		},

		toEditChapter() {
			return !this.isPropChapEmpty && !this.prop_chapter.parent;
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			// If request is to add chapter
			if (this.toAddChapter) {
				// Check total of private chapters that already created
				let total_private_chapter = this.$store.getters[
					"researchPaper/getPrivateChapterCount"
				];
				// If no chapter created then fetch minimum level chapter
				if (!total_private_chapter) {
					let levels = this.researchTemplate.map((item) => item.level);
					this.form.level = Math.min.apply(null, levels) + 1;
				}
				// If present then fetch max level chapter
				else {
					let levels = this.researchTemplate
						.filter((item) => item.origin == "private")
						.map((item) => item.level);

					this.form.level = Math.max.apply(null, levels) + 1;
				}
			}
			// If request is to add subchapter
			else if (this.toAddSubChapter) {
				this.form.level = this.prop_chapter.parent.level;
				this.form.sub_level = this.$store.getters[
					"researchPaper/getSublevelCount"
				](this.prop_chapter.parent.level);
			}
			// If request is to edit chapter/subchapter
			else if (this.toEditChapter) {
				Object.assign(this.form, this.prop_chapter);
			}
		},

		async setUuidForCreatedChapter(id) {
			let uuid = `${this.userProfile.id}-${uid()}`;

			// Save this id at backend
			await this.$store.dispatch("researchPaper/setTemplateUUID", {
				id: id,
				uuid,
			});

			return uuid;
		},

		async submit() {
			try {
				// Validate the form
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				// sanitize the form's input before submit
				let payload = this.form;
				payload.title = this.$xss.sanitize(this.form.title);
				payload.content = this.$xss.sanitize(this.form.content);

				// first sanitize then update or create chapter or add subchapter
				let response = await this.$store.dispatch(
					`researchPaper/${this.toEditChapter ? "editChapter" : "addChapter"}`,
					payload,
				);

				// Generate uuid for this newly created chapter/subchapter
				if (this.toAddChapter || this.toAddSubChapter) {
					let uuid = await this.setUuidForCreatedChapter(response.id);

					// create pad for this newly added chapter
					await this.$store.dispatch("etherpad/createPad", {
						pad_id: uuid,
						text: "",
					});
				}

				// Update state
				await this.$store.dispatch("researchPaper/template");

				// Reset form
				this.$refs.form.reset();

				// Close the dialog
				this.$emit("close");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
